//= pages/AnchorHealth.js

// eslint-disable-next-line
import React, { useState, Component, useContext, useEffect } from "react";
//
import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Column } from "primereact/column";
// import { Rating } from 'primereact/rating';
import { Button } from "primereact/button";
// import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from "primereact/checkbox";
import { Chart } from "primereact/chart";

// if we need an component that auto-rerenders itself, then:
// https://github.com/jcoreio/react-interval-rerender
// import Interval from 'react-interval-rerender'    // npm install --save react-interval-rerender
// export const Clock = () => (
//   <Interval delay={1000}>{() => new Date().toLocaleTimeString()}</Interval>
// )

import { SocketContext } from "../lib/socket_io_context";

import axios from "axios"; // npm i axios --save
import momentjs from "moment"; // npm i moment --save
import { f_max_age_ms, f_get_max_age_both } from "../config/config";

// import { CustomerService } from '../service/CustomerService';
// (is:)
// import axios from 'axios'
// export class CustomerService {
//     getCustomersMedium() {
//         return axios.get('assets/demo/data/customers-medium.json')
//             .then(res => res.data.data);
//     }
//     getCustomersLarge() {
//         return axios.get('assets/demo/data/customers-large.json')
//                 .then(res => res.data.data);
//     }
// }

// eslint-disable-next-line
const customers_medium_json = {
  data: [
    { id: 1000, name: "James Butt", country: { name: "Algeria", code: "dz" }, company: "Benton, John B Jr", date: "2015-09-13", status: "unqualified", activity: 17, representative: { name: "Ioni Bowcher", image: "ionibowcher.png" } },
    { id: 1001, name: "Josephine Darakjy", country: { name: "Egypt", code: "eg" }, company: "Chanay, Jeffrey A Esq", date: "2019-02-09", status: "proposal", activity: 0, representative: { name: "Amy Elsner", image: "amyelsner.png" } },
    { id: 1002, name: "Art Venere", country: { name: "Panama", code: "pa" }, company: "Chemel, James L Cpa", date: "2017-05-13", status: "qualified", activity: 63, representative: { name: "Asiya Javayant", image: "asiyajavayant.png" } },
  ],
};

const lineData = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      backgroundColor: "#2f4860",
      borderColor: "#2f4860",
      tension: 0.4,
    },
    {
      label: "Second Dataset",
      data: [28, 48, 40, 19, 86, 27, 90],
      fill: false,
      backgroundColor: "#00bb7e",
      borderColor: "#00bb7e",
      tension: 0.4,
    },
  ],
};

const convert_seconds_to_DHMS = function (secs) {
  // DHMS = Days, Hourse, Minutes, Seconds
  // let seconds = parseInt(secs, 10); // in case of string
  let seconds = secs;
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

function nr_to_padzero_string(nr, digits) {
  var str = "00000" + nr;
  return str.substr(-1 * digits);
}

const date_to_nicetime = (date) => {
  return momentjs(date).format("YYYY-MMM-DD HH:mm:ss");
};

// socket_io msg ==> api_do_refresh

// https://stackoverflow.com/questions/39426083/update-react-component-every-second
// componentDidMount() {
//   this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
// }
// componentWillUnmount() {
//   clearInterval(this.interval);
// }

// - - - - - - + + + - - - - - -
// about: useEffect
//
// // https://www.w3schools.com/react/react_useeffect.asp
// useEffect(() => {
//   // no 2nd param => Runs on every render
// });
// useEffect(() => {
//   // empty array as 2nd param => Runs only on the first render
// }, []);
// useEffect(() => {
//   // no 2ns param => Runs on the first render, And any time any dependency value changes
// }, [prop, state]);
// - - - - - - + + + - - - - - -

let max_age = f_max_age_ms();

export const AnchorHealthPage = () => {
  // const socket_io = useContext(SocketContext);

  // useEffect(() => {
  //   //
  //   update_api_anchorhealth();
  //   //
  //   socket_io.on("api_do_refresh", () => {
  //     console.log("socket_io.msg = api_do_refresh");
  //     update_api_anchorhealth();
  //   });
  // // }, []);
  // }, [socket_io]);

  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  // /api/reports?start=1646033418&end=1646934419&limit=1230
  // /api/reports?start=1646033418&end=1646934419&limit=1230&ffield=anchor_id&fvalue=anchor-a71&select=_lw_rssi_0
  // const url_reports     = api_url + "/api/reports";
  const url_reports = api_url + "/api/reports?start=1646033418&end=1646934419&limit=1230";
  //
  const url_anchors = api_url + "/api/anchors";
  const url_tokens = api_url + "/api/tokens";
  const url_timepoints = api_url + "/api/timepoints";
  const url_maps = api_url + "/api/maps";
  // url_anchors, url_tokens, url_timepoints, url_maps

  // const [ahealth_data, set_ahealth_data] = useState(lineData);
  const [ahealth_data, set_ahealth_data] = useState([]);

  const [anchor_table, set_anchor_table] = useState(null);
  const [anchor_filter, set_anchor_filter] = useState("");
  const [anchor_loading, set_anchor_loading] = useState(true);
  const [anchor_show_id, set_anchor_show_id] = useState(false);
  const [anchor_checkbox_show_id, set_anchor_checkbox_show_id] = useState([]);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
  // "returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC."
  const [interval_time, set_interval_time] = useState(Date.now());
  useEffect(() => {
    //
    update_api_anchorhealth();
    //
    const interval = setInterval(() => {
      set_interval_time(Date.now());
      // update_api_anchorhealth();
    }, 1000);
    //
    // to run a func at unmount, return a func here:
    return () => {
      clearInterval(interval);
    };
    //
    // eslint-disable-next-line
  }, []);

  const click_show_api_anchors = () => {
    window.open(url_anchors);
  };

  const example = {
    reports: [
      {
        _id: "622a39938e27c3f8d72ffa41",
        anchor_id: "anchor-a71",
        cloud_time: 1646934419,
        cloud_time_str: "2022-Mar-10 18:46:59",
        presence_tlist: [
          {
            tid: "18",
            bpl: "83",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa42",
          },
          {
            tid: "19",
            bpl: "83",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa43",
          },
          {
            tid: "41",
            bpl: "75",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa44",
          },
          {
            tid: "51",
            bpl: "71",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa45",
          },
          {
            tid: "52",
            bpl: "65",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa46",
          },
          {
            tid: "53",
            bpl: "71",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa47",
          },
          {
            tid: "53",
            bpl: "83",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa48",
          },
          {
            tid: "54",
            bpl: "80",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa49",
          },
          {
            tid: "55",
            bpl: "82",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa4a",
          },
          {
            tid: "56",
            bpl: "81",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa4b",
          },
          {
            tid: "57",
            bpl: "82",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa4c",
          },
          {
            tid: "58",
            bpl: "81",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa4d",
          },
          {
            tid: "63",
            bpl: "81",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa4e",
          },
          {
            tid: "64",
            bpl: "81",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa4f",
          },
          {
            tid: "ff",
            bpl: "83",
            rsi: "0",
            mis: "0",
            _id: "622a39938e27c3f8d72ffa50",
          },
        ],
        distance_tlist: [],
        _raw_string: ",no,repstring,,data,in,lwan,object,",
        _conv_string: ",no,repstring,,data,in,lwan,object,",
        _valid_report: true,
        _string_version: 0,
        _anchor_nr: 0,
        _anchor_seqnr: 0,
        _anchor_time: 0,
        _anchor_uptime: 0,
        _uplink_tech: "lorawan",
        _uplink_prov: "ttn",
        _lw_framecount: 12525,
        _lw_timestamp: "2022-03-10T17:46:59.283328824Z",
        _lw_gateway_id_0: "dragino-203d74",
        _lw_eui_0: "A84041FFFF203D74",
        _lw_rssi_0: -63,
        _lw_snr_0: 14.5,
        _lw_gateway_id_1: "",
        _lw_eui_1: "",
        _lw_rssi_1: 0,
        _lw_snr_1: 0,
        _lw_gateway_id_2: "",
        _lw_eui_2: "",
        _lw_rssi_2: 0,
        _lw_snr_2: 0,
        __v: 0,
      },
    ],
  };

  const lineData_example = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: "#2f4860",
        borderColor: "#2f4860",
        tension: 0.4,
      },
    ],
  };

  const update_api_anchorhealth = () => {
    // set_anchor_loading(true);
    axios
      .get(url_reports, {}, { timeout: 3000 })
      .then((data) => {
        const reports = data.data.reports || [];
        // const anchors_filtered = anchors.filter();
        //
        const rep = reports
          .filter((o) => o.anchor_id === "anchor-a71")
          .map((o) => {
            const time = o.cloud_time || 0;
            const rssi = o._lw_rssi_0 || 0;
            return time
              ? {
                  time: time,
                  rssi: rssi,
                }
              : null;
          });
        const ahealth = {
          labels: rep.map((o) => o.time),
          datasets: [
            {
              label: "rssi",
              data: rep.map((o) => o.rssi),
              backgroundColor: "#2f4860",
              borderColor: "#2f4860",
              tension: 0.4,
            },
          ],
        };
        //
        set_ahealth_data(ahealth);
      })
      .catch((err) => {
        set_ahealth_data(null);
        // set_ahealth_data(lineData);
      });
  };
  const anchor_on_checkbox_showid_change = (e) => {
    let selectedValue = [...anchor_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_anchor_checkbox_show_id(selectedValue);
    set_anchor_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  const anchor_table_header = (
    <div className="table-header">
      <h5>Anchors</h5>
      {/* <div className="field-checkbox">
            <Checkbox inputId="checkShowIDs_anchors" name="option" value="true" checked={anchor_checkbox_show_id.indexOf('true') !== -1} onChange={anchor_on_checkbox_showid_change} />
            <label htmlFor="checkShowIDs_anchors">show IDs</label>
        </div> */}
      {/* <Button label="/api/anchors" onClick={click_show_api_anchors} className="p-button-raised p-button-warning mr-2 mb-2" /> */}
      {/* <Button label="refresh" onClick={update_api_anchorhealth} className="p-button-raised p-button-warning mr-2 mb-2" /> */}
      <span className="">Last API update: {date_to_nicetime(interval_time)}</span>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={anchor_filter} onChange={(e) => set_anchor_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  const uptime_template = (data, props) => {
    const uptime = data[props.field];
    //
    // const max_age = 5 * 60;
    // const now_seconds = Math.floor( Date.now() / 1000 );
    // const age = now_seconds - data['rep_time'];
    // let niceUptime = "";
    // if (true) {
    // // if (age < max_age) {
    //   let DHMS = convert_seconds_to_DHMS(uptime);
    //   // niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours,2)}:${nr_to_padzero_string(DHMS.minutes,2)}:${nr_to_padzero_string(DHMS.seconds,2)} (h:m:s) `;
    //   niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours,2)}:${nr_to_padzero_string(DHMS.minutes,2)}:${nr_to_padzero_string(DHMS.seconds,2)}`;
    // } else {
    //   niceUptime = "(not up)";
    // }
    //
    const time = data.time || 0;
    // const time = data["cloud_time"];
    //
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    //
    const is_not_too_old = time_date_obj > date_now - max_age;
    if (time_date_obj > date_now - max_age) {
      let DHMS = convert_seconds_to_DHMS(uptime);
      const niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours, 2)}:${nr_to_padzero_string(DHMS.minutes, 2)}:${nr_to_padzero_string(DHMS.seconds, 2)}`;
      result_string = niceUptime;
    } else {
      result_string = "(down)";
    }
    //
    if (data["_uplink_tech"] === "lorawan") {
      result_string = "-";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const reptime_template = (data, props) => {
    let result_string = "";
    //
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    const is_not_too_old = time_date_obj > date_now - max_age;
    //
    let nice_time = date_to_nicetime(time_date_obj);
    result_string = nice_time;
    //
    if (false) {
      const data_hex = data["_anchor_hextime"];
      if (data_hex) {
        result_string = nice_time + " (" + time + "s, 0x" + data_hex + ")";
      } else {
        result_string = nice_time + " (" + time + "s)";
      }
    }
    //
    if (!is_not_too_old) {
      result_string = result_string + " (old)";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const tokenlist_template = (data, props) => {
    const presence_tlist = data[props.field];
    //
    const time = data.time || 0;
    // const time = data["cloud_time"];
    //
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    //
    if (time_date_obj > date_now - max_age) {
      let token_list_str = presence_tlist.join(",");
      result_string = token_list_str;
    } else {
      result_string = "-";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const location_template = (data, props) => {
    const presence_alist = data.location.presence_alist;
    const t_time = data.time || 0;
    const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
    // const tokens = presence_tlist.find(obj => obj.time == time).tokens;
    // let anchor_list_str = "Anchors:" + presence_alist.join(", ");
    let anchor_list_str = "Anchors:" + a_list.join(", ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {anchor_list_str}
      </>
    );
  };

  const fixes_template = (data, props) => {
    const fixes = data.fixes;
    const t_time = data.time || 0;
    let fixes_list = [];
    fixes.forEach((element) => {
      const tid = element.token_id;
      const presence_alist = element.location.presence_alist;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      let new_fix_str = "";
      if (a_list.length > 0) {
        new_fix_str = `T:${tid}->Anchors:` + a_list.join(",");
      } else {
        new_fix_str = `T:${tid}->Gone`;
      }
      fixes_list.push(new_fix_str);
    });
    const fixes_str = fixes_list.join("; ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {fixes_str}
      </>
    );
  };

  const [sortField, setSortField] = useState("anchor_id");
  const [sortOrder, setSortOrder] = useState(1);
  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  // const [multiSortMeta, setMultiSortMeta] = useState(
  //   [
  //     {field: '_uplink_tech',  order: -1},
  //     {field: 'anchor_id',     order: 1}
  //   ]
  // );

  // _uplink_tech:    { type: String,   required: false},
  // _uplink_prov:    { type: String,   required: false},
  // _lw_framecount:  { type: Number,   required: false},
  // _lw_timestamp:   { type: String,   required: false},
  // _lw_rssi:        { type: Number,   required: false},
  // _lw_lorasnr:     { type: Number,   required: false},

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          {/* https://www.primefaces.org/primereact/chart/ */}
          <Chart type="line" data={ahealth_data} />
        </div>
      </div>
    </div>
  );
};

//-EOF
