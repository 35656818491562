//= ./src/components/TokenPill.jsx

import React, { Component } from "react";
//
import { f_tpid_is_anchor, f_tpid_is_anchor_group, f_tpid_is_color, f_tpid_is_token, f_tpid_is_token_group, getColorFromHexstr, getColorFromNumber } from "../lib/functions";

class TokenPill extends Component {
  render() {
    // const hexstr = this.props.hexstr || "00";
    // if (hexstr === "00") return <></>;
    const tpid = this.props.tpid || "X-00";
    let type = undefined;
    let hexstr = undefined;
    let return_component = <></>; // default
    if (typeof tpid === "string") {
      type = tpid.split("-")[0] || undefined;
      hexstr = tpid.split("-")[1] || undefined;
    }
    if (type && hexstr && hexstr !== "00") {
      let color = getColorFromHexstr({ hexstr });
      //
      let type_is_A_or_AG = f_tpid_is_anchor(tpid) || f_tpid_is_anchor_group(tpid);
      let type_is_T_or_TG = f_tpid_is_token(tpid) || f_tpid_is_token_group(tpid);
      let type_is_Group = f_tpid_is_anchor_group(tpid) || f_tpid_is_token_group(tpid);
      const type_is_Color = f_tpid_is_color(tpid);
      if (type_is_Color) {
        type_is_A_or_AG = false;
        type_is_T_or_TG = true;
        type_is_Group = false;
        color = `#${hexstr}`;
      }
      //
      const borderColor = type_is_Group ? "lightgray" : color;
      const borderStyle = type_is_Group ? "inset" : "solid";
      const borderWidth = type_is_Group ? "6px" : "2px";
      const padding = type_is_Group ? "2px 4px" : "2px 12px";
      //
      const borderRadius = f_tpid_is_token(tpid) ? "8px" : "0px";
      //
      if (type_is_T_or_TG || type_is_A_or_AG) {
        return_component = (
          <span
            type="button"
            className="btn btn-primary btn-sm"
            style={{
              margin: "4px 4px",
              display: "inline-block",
              padding: padding,
              // border: `${borderWidth} solid ${borderColor}`,
              // borderStyle: borderStyle,
              borderWidth: borderWidth,
              borderColor: borderColor,
              borderStyle: borderStyle,
              borderRadius: borderRadius,
              color: "white",
              backgroundColor: color,
              fontSize: "16px",
              fontFamily: "monospace",
              fontWeight: "bold",
              marginRight: "6px",
              // cursor: this.props.is_moveable ? "move" : "auto",
              whiteSpace: "nowrap",
            }}
          >
            {`${type}-${hexstr}`}
          </span>
        );
        //
      }
      //
    }
    return return_component;
  }
}

export default TokenPill;

//-eof
