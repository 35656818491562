//= pages/FloorplanV3Page.js

// eslint-disable-next-line

import React, { useState, Component, useContext, useEffect } from "react";
import axios from "axios"; // npm i axios --save

import { f_max_age_ms, f_get_max_age_both } from "../config/config";
import { f_convert_selected_sensors_to_display_string, f_convert_sensors_to_display_string, f_is_object, f_js, f_pretty_json_stringify, f_tpid_is_anchor, getColorFromNumber } from "../lib/functions";

import AnchorMap from "../components/AnchorMap";
import Draggable from "../components/common/Draggable";
//
// import floorplan from "../layout/images/Odense-DP04w.png";
import floorplan from "../layout/images/Odense-DP04w.svg";
import { resolve } from "path";
import { rejects } from "assert";

const api_url = window._env_.API_BASE_URL;
const url_anchors_v3 = api_url + "/api/anchors";
let update_interval_s = 2;

const DragState = (props) => {
  // const state_anchor_pos = props.state_anchor_pos;
  // const state_show_dragging = props.state_show_dragging;
  return (
    <>
      {props.state_show_dragging && props.state_show_dragging.tpid && (
        <div
          // visibility={props.state_show_dragging.tpid ? "visible" : "hidden"}
          style={{
            border: "1px solid black",
            padding: "6px",
            display: "inline-block",
            textAlign: "center",
            // position: "absolute",
            // zIndex: 1,
            // top: "160px",
            // left: "160px",
          }}>
          <p>
            Dragging: {props.state_show_dragging.tpid} &nbsp; x={props.state_show_dragging.x} &nbsp; y={props.state_show_dragging.y}
          </p>
        </div>
      )}
    </>
  );
};

export const FloorplanV3Page = (props) => {
  //

  // https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately
  // Much like .setState() in class components created by extending React.Component or React.PureComponent, the state update using the updater provided by useState hook is also asynchronous, and will not be reflected immediately.
  // Also, the main issue here is not just the asynchronous nature but the fact that state values are used by functions based on their current closures, and state updates will reflect in the next re-render by which the existing closures are not affected, but new ones are created. Now in the current state, the values within hooks are obtained by existing closures, and when a re-render happens, the closures are updated based on whether the function is recreated again or not.
  // ...
  // If you want to perform an action on state update, you need to use the useEffect hook, much like using componentDidUpdate in class components since the setter returned by useState doesn't have a callback pattern
  // useEffect(() => {
  //   // action on update of movies
  // }, [movies]);
  //
  // JDG: in my words: use React-state only for render() updates! Outside render() the state-vars have no meaning, use normal-variables for that

  let [state_anchors, f_setstate_anchors] = useState(null);
  // const [state_interval_time, f_setstate_interval_time] = useState(Date.now());
  // let [state_anchor_pos, f_setstate_anchor_pos] = useState(null);
  // let [state_is_dragging, f_setstate_is_dragging] = useState(false);
  let [state_show_dragging, f_setstate_show_dragging] = useState(false);

  // const [state_x, f_setstate_x] = useState(0);
  // const [state_y, f_setstate_y] = useState(0);
  // let var_x = 0;
  // let var_y = 0;

  let max_age = f_max_age_ms();

  let semistate_is_dragging = false;

  useEffect(() => {
    //
    f_update_api_anchors();
    //
    const interval = setInterval(async () => {
      // f_setstate_interval_time(Date.now());
      f_update_api_anchors();
      // f_set_anchor_position_ui();
      //
    }, update_interval_s * 1000);
    // return a function that is to be run at 'componentWillUnmount':
    return () => {
      clearInterval(interval); // undo 'setInterval()'
    };
    //
    // eslint-disable-next-line
  }, []);

  const f_update_api_anchors = () => {
    //
    if (semistate_is_dragging) {
      return;
    }
    console.log(`# f_update_api_anchors() start `);
    //
    async function fa_wrapper() {
      //
      let anchors = undefined;
      //
      const result = await axios.get(url_anchors_v3, {}, { timeout: 3000 });
      anchors = result.data && result.data.anchors ? result.data.anchors : null;
      //
      const filter_non_tpid = true;
      if (filter_non_tpid) {
        const anchors_non_tpid = anchors.filter((item) => {
          return f_tpid_is_anchor(item.tpid);
        });
        anchors = anchors_non_tpid;
      }
      //
      if (true || props.show_items_limit === "recent") {
        const anchors_new = anchors.filter((item) => {
          let time_data = new Date(item.time * 1000);
          let time_now = Date.now();
          const is_old = time_data < time_now - max_age * 2;
          return !is_old;
        });
        anchors = anchors_new;
        f_setstate_anchors(anchors);
      }
      //
    }
    fa_wrapper();
    //
  };

  const f_set_anchor_position_ui = () => {
    // do only once:
    // if (true) {
    //   // if (!state_anchor_pos) {
    //   console.log(`# f_set_anchor_position_ui() ONCE !!! `);
    //   let pos = { ...state_anchor_pos };
    //   if (!state_anchors) return;
    //   state_anchors.forEach((my_anchor) => {
    //     const my_anchor_config = my_anchor.config || {};
    //     const my_anchor_config_position_ui = my_anchor_config.position_ui || {};
    //     pos[my_anchor.tpid] = {
    //       x: my_anchor_config_position_ui.x || 0,
    //       y: my_anchor_config_position_ui.y || 0,
    //     };
    //   });
    //   // f_setstate_anchor_pos(pos);
    //   f_print_state_anchor_pos();
    // }
  };

  const f_print_state_anchor_pos = () => {
    // state_anchor_pos && console.log(`# state_anchor_pos=${f_js(state_anchor_pos)}`);
  };

  const fa_update_api_anchor = ({ anchor }) => {
    return axios.put(url_anchors_v3 + "/" + anchor.id, anchor, { timeout: 3000 }); // NOTE: we return the Promise
  };

  const onStart = (x, y, a_tpid) => {
    console.log(`# onStart called tpid=${a_tpid} x=${x} y=${y}`);
    // f_setstate_is_dragging(a_tpid);
    // f_setstate_is_dragging(true);
    semistate_is_dragging = true;
    f_setstate_show_dragging({ x, y, tpid: a_tpid });
  };
  const onMove = (x, y, a_tpid) => {
    // console.log(`# onMove called x=${x} y=${y}`);
    f_setstate_show_dragging({ x, y, tpid: a_tpid });
  };
  const onStop = async (x, y, a_tpid) => {
    console.log(`# onStop() called tpid=${a_tpid} x=${x} y=${y}`);
    // f_setstate_x(x);
    // f_setstate_y(y);
    // f_setstate_is_dragging(false);
    semistate_is_dragging = false;
    f_setstate_show_dragging(false);
    //
    // if (state_anchor_pos) {
    //   let pos = { ...state_anchor_pos };
    //   pos[a_tpid] = {
    //     x: x,
    //     y: y,
    //   };
    //   // f_setstate_anchor_pos(pos);
    //   f_print_state_anchor_pos();
    // }
    //
    try {
      if (state_anchors && Array.isArray(state_anchors)) {
        //
        // send API.put() :
        const anchor = state_anchors.find((this_anchor) => this_anchor.tpid === a_tpid);
        if (anchor) {
          // anchor.position_ui = { x, y };
          if (f_is_object(anchor.config)) {
            anchor.config = {};
          }
          anchor.config.position_ui = { x, y };
          console.log(`# onStop() will call fa_update_api_anchor() with a_tpid=${a_tpid} position_ui=${f_js(anchor.config.position_ui)}`);
          await fa_update_api_anchor({ anchor });
        }
        //
        // update state_anchors:
        //
      }
    } catch (err) {}
    //
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <div className="img-overlay-wrap">
            <img className="mr-3" src={floorplan} alt="odense-floorplan" />
            <DragState
              // state_anchor_pos={state_anchor_pos}
              state_show_dragging={state_show_dragging}
            />
            {state_anchors &&
              state_anchors.map((anchor, index) => {
                // if (!anchor || anchor.tpid !== "A-0068") {
                // if (!anchor) {
                //   return undefined;
                // }
                let this_anchor_x = 0;
                let this_anchor_y = 0;
                if (anchor.config && anchor.config.position_ui) {
                  this_anchor_x = anchor.config.position_ui.x || 0;
                  this_anchor_y = anchor.config.position_ui.y || 0;
                }
                // if (!this_anchor_x && !this_anchor_y) {
                // }
                return (
                  <Draggable
                    // key={anchor.tpid}
                    x={this_anchor_x}
                    y={this_anchor_y}
                    tpid={anchor.tpid}
                    // x={var_x}
                    // y={var_y}
                    //
                    gridX={12}
                    gridY={12}
                    onStart={onStart}
                    onMove={onMove}
                    onStop={onStop}>
                    <AnchorMap key={anchor.tpid} anchor={anchor} />
                  </Draggable>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

//-eof
